@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

body,
* {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* CSS */
.css-mtsker-MuiTypography-root {
  font-family: "Montserrat", sans-serif;
}
